// import { useForm } from 'react-hook-form';
import { useState } from "react";
import { signInWithEmailAndPassword } from '@/lib/auth/client';
import Button from "./../elements/button";
import { City, State } from "country-state-city";
import useSession from 'hooks/useSession';
import { ICity } from 'country-state-city'
import { Address } from 'interfaces/address.interface';
import { User } from 'interfaces/users.interface';
import { SubmitHandler, useForm, FieldValues } from 'react-hook-form';


type FormValues = {
    firstName?: string;
    lastName?: string;
    email: string;
    password: string;
};

export default function SignInForm({ setProceed }: {
    setProceed: (state: boolean) => void;
}) {
    const { register, handleSubmit, formState: { errors }, setError, getValues } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const session = useSession();

    const onSubmit = async () => {
        setIsLoading(true);
        try {
            let data = getValues();
            let result: {
                user: User,
            } = await signInWithEmailAndPassword(data['email'], data['password']);

            const { user } = result

            console.log('result', result)
            if (result && user.address.country && user.address.state && user.address.city && user.address.country != 'XX') {
                await localStorage.setItem('user:address:country', user.address['country']);
                await localStorage.setItem('user:address:state', user.address['state']);
                await localStorage.setItem('user:address:city', user.address['city']);

                const stateData = State.getStatesOfCountry(user.address['country']).filter(v => v.isoCode === user.address['state']);
                if (stateData.length > 0) {
                    if (stateData[0].latitude && stateData[0].longitude) {
                        await localStorage.setItem('user:address:latitude', stateData[0].latitude);
                        await localStorage.setItem('user:address:longitude', stateData[0].longitude);
                    }

                    const cityData = (City.getCitiesOfState(user.address['country'], user.address['state']) as ICity[]).filter((v: ICity) => v.name === user.address['city']);
                    if (cityData.length > 0 && cityData[0].latitude && cityData[0].longitude) {
                        await localStorage.setItem('user:address:latitude', cityData[0].latitude);
                        await localStorage.setItem('user:address:longitude', cityData[0].longitude);
                    }
                }
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            } else {
                setProceed(true)
            }
            console.log(result)
        } catch (e) {
            setIsLoading(false)
            /* setError('email', {
                type: "manual",
                message: result.error.message,
            }); */
            console.error(e)
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="mt-0">
                <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-5 text-gray-700"
                >
                    Email address
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                    <input
                        id="email"
                        className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                        type="email"
                        {...register('email', {
                            required: 'Please enter an email',
                            pattern: {
                                value: /^("(?:[!#-\[\]-\u{10FFFF}]|\\[\t -\u{10FFFF}])*"|[!#-'*+\-/-9=?A-Z\^-\u{10FFFF}](?:\.?[!#-'*+\-/-9=?A-Z\^-\u{10FFFF}])*)@([!#-'*+\-/-9=?A-Z\^-\u{10FFFF}](?:\.?[!#-'*+\-/-9=?A-Z\^-\u{10FFFF}])*|\[[!-Z\^-\u{10FFFF}]*\])$/u,
                                message: 'Not a valid email',
                            },
                        })}
                    />
                    {errors && errors.email && (
                        <div className="mt-2 text-xs text-red-600">
                            {errors.email.message}
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-0">
                <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-5 text-gray-700"
                >
                    Password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                    <input
                        id="password"
                        className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                        type="password"
                        {...register('password', {
                            required: 'Please enter a password',
                            minLength: {
                                value: 6,
                                message: 'Should have at least 6 characters',
                            },
                        })}
                    />
                    {errors && errors.password && (
                        <div className="mt-2 text-xs text-red-600">
                            {errors.password.message}
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm md:w-full">
                    <Button isLoading={isLoading} title={'Sign in with Email'} error={null} />
                </span>
            </div>
        </form>
    );
}
